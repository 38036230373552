<template>
  <div class="bg-white w-full overflow-hidden mt-8 rounded-sm">
    <div style="height: 400px;" class="w-full">
      <l-map ref="map" :zoom="zoom" :center="center" :options="mapOptions">
        <l-tile-layer :url="url"/>
        <l-marker :lat-lng="getLatLng(location)" v-for="(location, index) in homes" :key="index" @click="selectedLocation = location">
          <l-icon :icon-url="getMarkerImageUrl(location)" :icon-size="[35,35]"/>
        </l-marker>
        <l-control v-if="selectedLocation">
          <div class="h-80 w-68 bg-white right-0 rounded-sm mt-3 mr-5 p-3">
            <button @click="selectedLocation = null" class="absolute right-0 mr-8">
              <img src="@/assets/img/close.svg" alt="Close">
            </button>
            <div class="flex flex-col h-full">
              <div class="flex-1">
                <Icon class="text-red-600 my-auto absolute text-2xl" icon="fa:exclamation-circle" v-if="selectedLocation.home.warnings > 0"/>
                <div class="flex justify-between mt-10">
                  <div>
                    <p class="text-wilmsBlue text-lg font-bold">{{ selectedLocation.home.name }}</p>
                    <p class="text-gray-500 font-medium -mt-1">{{ selectedLocation.home.address }}</p>
                    <p class="text-gray-500 font-medium -mt-1">{{ selectedLocation.home.postcode + ' ' + selectedLocation.home.city }}</p>
                  </div>
                  <div class="flex flex-col justify-center items-end">
                    <div class="bg-wilmsBlueBackground w-max rounded-sm px-2 text-xs text-wilmsBlue font-medium mt-1 py-1">{{ totalDevices(selectedLocation.home) }}</div>
                    <div class="bg-red-200 w-max rounded-sm px-2 text-xs text-red-600 font-medium mt-1 py-1">{{ totalWarnings(selectedLocation.home.warnings) }}</div>
                  </div>
                </div>
                <div class="flex mt-2">
                  <div class="flex bg-green-200 w-6 h-6 rounded-full justify-center">
                    <img src="@/assets/img/iot/np_customer.png" alt="" class="my-auto">
                  </div>
                  <p class="ml-2 text-gray-500 font-medium">--</p>
                </div>
              </div>
              <div class="flex-none flex flex-col">
                <div class="shadow-md rounded-sm flex justify-between p-3">
                  <p class="text-wilmsBlue font-bold text-sm">{{ $t('Installation') }}</p>
                  <p class="text-gray-500 font-medium text-sm">{{ getDate(selectedLocation.home.createdAt) }}</p>
                </div>
                <div class="shadow-md rounded-sm flex justify-between p-3 mt-2">
                  <p class="text-wilmsBlue font-bold text-sm">{{ $t('Last maintenance') }}</p>
                  <p class="text-gray-500 font-medium text-sm">{{ getDate(selectedLocation.home.maintenance) }}</p>
                </div>
                <div class="w-max mx-auto">
                  <router-link :to="{ name: 'iot.installer.details', params: { home: selectedLocation.home.id }}">
                    <div class="mt-2 bg-wilmsBlue text-white rounded-sm px-3 py-1 cursor-pointer">
                      {{ $t('View all info') }}
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </l-control>
      </l-map>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { latLng } from 'leaflet'
import { LMap, LTileLayer, LMarker, LIcon, LControl } from 'vue2-leaflet'
import { Icon } from '@iconify/vue2'

export default {
  props: ['homes'],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LControl,
    Icon
  },
  data () {
    return {
      selectedLocation: null,
      zoom: 13,
      center: latLng(50.93106, 5.33781),
      url: 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
      mapOptions: {
        attributionControl: false,
        zoomSnap: 0.5
      }
    }
  },
  mounted () {
    if (this.homes != null) this.fitBounds()
  },
  watch: {
    homes () {
      this.fitBounds()
    }
  },
  methods: {
    getLatLng (location) {
      return {
        lat: location.home.latitude,
        lng: location.home.longitude
      }
    },
    getMarkerImageUrl (location) {
      if (location.home.warnings > 0) return require(`@/assets/img/iot/${location.home.marker ?? 'np_pin_warning'}.svg`)
      return require(`@/assets/img/iot/${location.home.marker ?? 'np_pin'}.svg`)
    },
    fitBounds () {
      if (!this.homes.length) return
      this.$nextTick(() => {
        this.$refs.map.mapObject.fitBounds(this.homes.map(home => this.getLatLng(home)), { padding: [30, 30] })
      })
    },
    totalDevices (home) {
      let total = 0
      home.rooms.forEach(room => { total += room.devices.length })
      return total === 1 ? total + ' ' + this.$t('unit') : total + ' ' + this.$t('units')
    },
    totalWarnings (warnings) {
      return warnings === 1 ? warnings + ' ' + this.$t('warning') : warnings + ' ' + this.$t('warnings')
    },
    getDate (date) {
      return moment(date).format('DD/MM/YYYY')
    }
  }
}
</script>
