<template>
  <div class="container">
    <p class="text-wilmsLightBlue mt-12">{{ date }}</p>
    <p class="text-wilmsBlue text-2xl font-bold">{{ $t('Welcome back') }}, {{ currentUser.profile ? currentUser.profile.first_name : currentUser.email }}</p>
    <Map :homes="this.$store.getters['HomesModule/getHomes']" />
    <div class="mt-12 grid grid-cols-4 gap-5 mb-8 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
      <router-link :to="{ name: 'iot.installer.homes' }">
        <div class="shadow-md bg-white rounded-sm flex py-3 cursor-pointer">
          <div class="px-5"><Icon class="mt-1 text-wilmsLightBlue text-xl" icon="fa:home"/></div>
          <div class="flex-auto">
            <p class="text-sm text-gray-400 -mb-1">{{ $t('Locations') }}</p>
            <p class="text-2xl text-wilmsBlue font-bold">{{ this.$store.getters['HomesModule/getTotalLocations'] }}</p>
          </div>
          <div class="pr-5 flex flex-wrap content-center">
            <div class="w-8 h-8 bg-wilmsVeryLightBlue rounded-full flex flex-wrap content-center justify-center">
              <Icon class="text-wilmsLightBlue text-lg ml-1" icon="fa:angle-right"/>
            </div>
          </div>
        </div>
      </router-link>
      <router-link :to="{ name: 'iot.installer.homes' }">
        <div class="shadow-md bg-white rounded-sm flex py-3 cursor-pointer">
          <div class="px-5"><img class="mt-1 mx-auto h-6 text-wilmsBlue" alt="" src="@/assets/img/iot/np_fan.svg"/></div>
          <div class="flex-auto">
            <p class="text-sm text-gray-400 -mb-1">{{ $t('Installations') }}</p>
            <p class="text-2xl text-wilmsBlue font-bold">{{ this.$store.getters['HomesModule/getTotalDevices'] }}</p>
          </div>
          <div class="pr-5 flex flex-wrap content-center">
            <div class="w-8 h-8 bg-wilmsBlueBackground rounded-full flex flex-wrap content-center justify-center">
              <Icon class="text-wilmsBlue text-lg ml-1" icon="fa:angle-right"/>
            </div>
          </div>
        </div>
      </router-link>
      <router-link :to="{ name: 'iot.installer.homes' }">
        <div class="shadow-md bg-white rounded-sm flex py-3 cursor-pointer">
          <div class="px-5"><Icon class="mt-1 text-red-500 text-xl" icon="fa:check-circle"/></div>
          <div class="flex-auto">
            <p class="text-sm text-gray-400 -mb-1">{{ $t('Warnings') }}</p>
            <p class="text-2xl text-wilmsBlue font-bold">{{ this.$store.getters['HomesModule/getTotalWarnings'] }}</p>
          </div>
          <div class="pr-5 flex flex-wrap content-center">
            <div class="w-8 h-8 bg-red-100 rounded-full flex flex-wrap content-center justify-center">
              <Icon class="text-red-500 text-lg ml-1" icon="fa:angle-right"/>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import Map from '@/components/iot/maps/Map'
import moment from 'moment'
import { Icon } from '@iconify/vue2'

export default {
  components: {
    Map,
    Icon
  },
  async mounted () {
    this.$emit('changeHeader', [[this.$t('IOT'), { name: 'iot.installer' }]])
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      if (this.$store.getters['HomesModule/getHomes'].length === 0) await this.$store.dispatch('HomesModule/fetchHomes')
    }
  },
  computed: {
    date () {
      moment.locale(this.$i18n.locale)
      return moment(String(new Date().toISOString())).format('dddd, DD MMMM YYYY')
    },
    currentUser () {
      return this.$store.getters.getCurrentUser
    }
  }
}
</script>
